import { Col, Row, Tooltip } from "reactstrap";
import fotoAdan from "../../images/adan.webp";
import bandeiraRs from "../../images/bandeira_rs.jpg";
import styles from "./styles.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import infos from "../../data/infos.json";
import { useState } from "react";

export default function Header() {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const tooltipToggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <Row className="mt-5">
      <Col xs="12" md="6">
        <img
          src={fotoAdan}
          alt="Foto do Missionário"
          className={styles.missionaryPicture}
        />
      </Col>
      <Col xs="12" md="6" className="mt-2">
        <div className="text-start">
          <img src={bandeiraRs} alt="Bandeira" className={styles.bandeiraRs} />
          <p className="justify">
            <span className="h4">{infos.name.toUpperCase()}</span>
            <br />
            <span className="h5">{infos.mission}</span>
            <br />
            <span className="h6">
              {infos.start} ~ {infos.end}
            </span>
            <br />
            <a href={infos.missionOfficeLink} target="_blank" rel="noreferrer">
              Escritório da Missão
            </a>
            <br />
            <br />
            Olá! Obrigado por entrar no meu site de doações para meu enxoval da
            missão! Estou muito animado para poder servir ao senhor nestes
            próximos dois anos de minha vida!
            <br />
            Aqui neste site você poderá selecionar quais itens e qual quantidade
            você deseja realizar a doação. Após seu preenchimento: 
            <br />
            <br />
            <ul>
              <li>Assim que possível entrarei em contato com você;</li>
              <li>
                Entregar os itens para o bispo Rocha na{" "}
                <a
                  href="https://maps.app.goo.gl/wBPPCsyqvSayeZXS6"
                  target="_blank"
                  id="tooltipChurch"
                  rel="noreferrer"
                >
                  <span>
                    <strong>capela</strong>

                    <Tooltip
                      isOpen={tooltipOpen}
                      target="tooltipChurch"
                      toggle={tooltipToggle}
                    >
                      Clique para ir ao endereço da igreja
                    </Tooltip>
                  </span>{" "}
                  <strong>da ala Cerrado</strong>;
                </a>
              </li>
              <li>
                Me enviar o valor em dinheiro no pix:{" "}
                <strong>(15) 99644-3777 (Nubank)</strong>.
              </li>
            </ul>
            Gostaria somente de brevemente prestar meu testemunho à você:
            <br />
            <br />
            <span
              dangerouslySetInnerHTML={{
                __html: infos.testimony.replace(/\n/g, "<br/>"),
              }}
            ></span>
            <blockquote className="blockquote mt-3">
              <p>
                <FontAwesomeIcon icon={faQuoteLeft} className="fa-xs me-1" />
                ...Em verdade vos digo que, quando o fizestes a um destes meus
                pequeninos irmãos, a mim o fizestes.
                <FontAwesomeIcon icon={faQuoteRight} className="fa-xs ms-1" />
              </p>
            </blockquote>
            <figcaption className="blockquote-footer">
              <cite title="Escritura">
                <a href="https://www.churchofjesuschrist.org/study/scriptures/nt/matt/25?lang=por&id=p40#p40">
                  Mateus 25:40
                </a>
              </cite>
            </figcaption>
          </p>
        </div>
      </Col>
    </Row>
  );
}
