import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { Button, Col, Form, Input, Row } from "reactstrap";
import styles from "./styles.module.css";
import "./teste.css"

export default function Login() {
  axios
    .get("https://enxovalmissionario-api.onrender.com/users")
    .then((response) => {
      var users = response.data;
      console.log(users);
    })
    .catch(() => console.error());

  const handleLogin = (event) => {
    event.preventDefault();

    const username = document.getElementById("username").value;
    const password = document.getElementById("password").value;
    let users;

    axios
      .get("https://enxovalmissionario-api.onrender.com/users")
      .then((response) => {
        users = response.data;
        console.log(users);

        const user = users.find(
          (user) =>
            user.username.toUpperCase() === username.toUpperCase() &&
            user.password === password
        );
    
        if (user) {
          //logado
        } else {
          //não logado
        }
      })
      .catch(() => console.error());
  };

  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
      <Row className={styles.login + " px-5 py-3 rounded"}>
        <Col xs="12">
          <Form onSubmit={handleLogin}>
            <Row className="my-3">
              <Col xs="12">
                <Input
                  type="text"
                  placeholder="Informe o login"
                  id="username"
                  required
                />
              </Col>
            </Row>
            <Row className="my-3">
              <Col xs="12">
                <Input
                  type="password"
                  placeholder="Informe a senha"
                  id="password"
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="text-end">
                <Button type="submit" color="success">
                  <FontAwesomeIcon icon={faRightFromBracket} className="mr-1" />{" "}
                  Entrar
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
}
